/* Middle */
.Middle {
  margin: 0 auto !important;
  overflow: visible !important;
  /* Add margins to layout blocks */
  .CategoryList {
    .CategoryBaseTable {
      .ImgLeft,
      .ImgRight,
      .TextComplete,
      .TextLeft,
      .TextRight {
        margin: 16px 0 !important;
      }
    }

    .CategoryBaseTable.TextComplete {
      margin: 16px 0 !important;
    }

    div.CategoryImage {
      float: none !important;
      margin-bottom: 24px 0 30px 0 !important;
    }
  }
  /* PageThis */
  .PageThis {
    display: $display-none;
  }
  /* center the default slider */
  .contentslider {
    margin: 0 auto !important;
  }
}
@media screen and (max-width: 768px) {
  /* Middle */
  .Middle {
    .CategoryList {
      .CategoryBaseTable {
        .ImgLeft,
        .ImgRight,
        .TextLeft,
        .TextRight {
          padding: 0 !important;
          width: 100% !important;
        }

        .ImgRight {
          img {
            max-width: 100% !important;
            width: 100% !important;
          }
        }
      }

      .CategoryBase {
        .ImgLeft {
          img {
            max-width: 100% !important;
            width: 100% !important;
          }
        }
      }
    }

    .CategoryText {
      img {
        height: auto !important;
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}