/* New and Sale icons */
.HotDealTopFloatin,
.ListItemProduct,
.ProductListImageBox,
.SlimHotDealTop {
  .NewProduct {
    background: #00883e !important;
    padding: 2px !important;
    color: white !important;
    display: table-cell !important;
    font-size: 13px !important;
    font-weight: normal !important;
    z-index: 99 !important;
    margin: 10px 0 0 20px !important;
    text-align: center !important;
    border-radius: 50%;
    vertical-align: middle;
    height: 40px;
    width: 40px;
    line-height: 3.1 !important;
  }
}

.ProductDetails {
  .NewProduct {
    position: absolute;
    background: #00883e !important;
    padding: 2px !important;
    color: white !important;
    font-size: 13px !important;
    z-index: 99;
    min-height: unset !important;
    min-width: unset !important;
    font-weight: normal !important;
    text-align: center !important;
    border-radius: 50%;
    vertical-align: middle;
    height: 40px;
    width: 40px;
    line-height: 3.1 !important;
  }
}

.ComparisonTable,
.ListItemProductContainer {
  .NewProduct {
    display: $display-none;
  }
}

.HotDealList {
  .SlimHotDeal {
    .SlimHotDealTop {
      .NewProduct {
        margin: 0 !important;
        line-height: 2.8 !important;
        height: 35px !important;
        width: 35px !important;
      }
    }
  }
}
/* Imagearea icons */
.HotDealTopFloatin,
.ListItemProduct,
.ProductListImageBox,
.SlimHotDealTop {
  .NewProduct {
    float: none !important;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.Tarjous {
  position: absolute;
  top: 0;
  right: 0;
}
/* Stock warnings */
.ProductOnStockIcon,
.ProductOutStockIcon,
.ProductWarnStockIcon {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50%;
}

.ProductOnStockIcon {
  background: #009347 !important;
}

.ProductOutStockIcon {
  background: #db3058 !important;
}

.ProductWarnStockIcon {
  background: #f90 !important;
}
/* Star icons */
.ProductRatingEnabledIcon {
  background: url("https://vdt.vilkas.fi/VDT/master/Graphics/rating-star-full.svg") !important;
}

.ProductRatingEnabledIconSmall {
  background: url("https://vdt.vilkas.fi/VDT/master/Graphics/rating-star-full-small.svg") !important;
}

.ProductRatingDisabledIcon {
  background: url("https://vdt.vilkas.fi/VDT/master/Graphics/rating-star-empty.svg") !important;
}

.ProductRatingDisabledIconSmall {
  background: url("https://vdt.vilkas.fi/VDT/master/Graphics/rating-star-empty-small.svg") !important;
}

.LinkProductRating {
  background: url("https://vdt.vilkas.fi/VDT/master/Graphics/rating-star-full.svg") no-repeat 0 0 !important;
}
/* Flags */
.LocaleFlags {
  a[href^="../sv_SE"],
  a[href^="?Locale=sv_SE"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Flags/se.svg");
  }

  a[href^="../fi_FI"],
  a[href^="?Locale=fi_FI"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Flags/fi.svg");
  }

  a[href^="../no_NO"],
  a[href^="?Locale=no_NO"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Flags/no.svg");
  }

  a[href^="../en_GB"],
  a[href^="?Locale=en_GB"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Flags/gb.svg");
  }

  a[href^="../da_DK"],
  a[href^="?Locale=da_DK"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Flags/dk.svg");
  }

  a[href^="../de_DE"],
  a[href^="?Locale=de_DE"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Flags/de.svg");
  }

  a[href^="../fr_FR"],
  a[href^="?Locale=fr_FR"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Flags/fr.svg");
  }

  a[href^="../ru_RU"],
  a[href^="?Locale=ru_RU"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Flags/ru.svg");
  }

  a {
    background-repeat: no-repeat!important;
    background-position: center!important;
    display: inline-block;
    width: 15px;
    margin-right: 4px;
    border: 1px solid #737373;
    border-radius: 100%;
    height: 15px;
    background-size: cover !important;
    transition: $transition;

    &:hover {
      box-shadow: $box-shadow-small;
      border: 1px solid black;
    }

    img {
      visibility: hidden;
    }
  }
}
/* Coin flags */
.Coins {
  a[href$="Currency=EUR"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Graphics/Currency/eur.svg");
  }

  a[href$="Currency=SEK"] {
    background: url("https://vdt.vilkas.fi/VDT/master/Graphics/Currency/sek.svg");
  }

  a {
    background-repeat: no-repeat!important;
    background-position: center!important;
    display: inline-block;
    width: 20px;
    margin-right: 4px;
    border: 1px solid #737373;
    border-radius: 100%;
    height: 20px;
    background-size: cover !important;

    &:hover {
      box-shadow: $box-shadow-small;
      border: 1px solid black;
    }

    img {
      visibility: hidden;
    }
  }

  span.CoinLabel {
    display: none;
  }
}