/* Page width */
.GeneralLayout,
img {
  max-width: 100% !important;
}

.GeneralLayout {
  width: 100% !important;
}

.Footer,
.Header,
.NavBarBottom,
.NavBarRoof,
.NavBarTop {
  .PropertyContainer {
    max-width: $site-width !important;
  }
}

.Middle {
  max-width: $site-width !important;
}
/* Terms and Conditions, Privacy policy etc. width */
.Imprint,
.PrivacyPolicy,
.ShippingAndPaymentInformation,
.TermsAndConditions {
  .Middle {
    max-width: $page-width !important;
  }
}
/* Checkout width */
.MultipleStep-Container {
  .CheckOut {
    max-width: $checkout-width;
  }
}