/*.custom-search-background {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background-color: #000;
  cursor: pointer;
  opacity: 0;
  transition-duration: 0.3s;
  visibility: hidden;
  z-index: 1000;
}

.custom-search-background.Visible {
  opacity: 0.25;
  visibility: visible;
}*/
.SearchElement {
  form {
    margin: 0 !important;
  }
}

.Header {
  .SearchElement {
    .Entry {
      .InputField.SearchForm.horizontal-search {
        background: white !important;

        .Search {
          background: white !important;
        }
      }
    }

    .SearchForm.horizontal-search {
      .SearchIcon {
        background: none !important;
        height: auto !important;

        &::before {
          content: "\f002" !important;
          font-family: 'FontAwesome' !important;
          font-size: 14px !important;
          display: block !important;
          color: black !important;
        }
      }
    }
  }
}

.de_epages-remotesearchUiSuggest-box {
  position: absolute;
  z-index: 10000;
  display: block!important;
  width: auto!important;
  background: transparent!important;

  ul {
    max-width: 90%;
    padding: 10px;
    background: white;
    width: 100%!important;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 5px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    li {
      margin: 0;
      padding: 8px;
      background: white;

      img {
        max-width: 50px;
        float: none;
        margin: 0 4px 0 0;
      }

      &:hover {
        background-color: white;
        cursor: pointer;
      }

      &:active {
        background-color: white;
        color: black;
      }
    }

    li.ui-hover {
      background-color: white;
      cursor: pointer;
    }

    li.ui-active {
      background-color: white;
      color: black;
    }

    li.categories.ui-active,
    li.categories.ui-hover,
    li.manufacturers.ui-active,
    li.manufacturers.ui-hover,
    li.products.ui-active,
    li.products.ui-hover {
      box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
    }

    li.Separator,
    li.Separator.ui-hover,
    li.Separator:hover {
      padding-left: 0;
      background-color: white;
      cursor: default;
      color: black;
      padding-bottom: 0;
      font-size: 11px;
    }

    li.Separator {
      width: 100%;
    }

    li.categories,
    li.manufacturers,
    li.products {
      border: $border;
      border-radius: $border-radius-small;
    }
  }

  .categoryName,
  .manufacturer,
  .searchfor {
    padding-left: 10px;
  }
}