/* Basket overlay*/
#BasketOverLay {
  border: none !important;
  margin-top: 0 !important;
  background: #1d1d1d !important;
  left: 0 !important;
  min-width: 100% !important;
  position: fixed !important;
  top: 0 !important;
  z-index: 9999999999999 !important;
  text-align: center !important;
  padding: 18px 0 !important;
  -webkit-box-shadow: 0 2px 30px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.3) !important;
  font-size: 14px !important;
  /* for chrome and safari*/
  -webkit-animation-duration: 1s;
  -webkit-animation-name: slidein;
  /*for firefox*/
  -moz-animation-duration: 1s;
  -moz-animation-name: slidein;
  /* for opera*/
  -o-animation-duration: 1s;
  -o-animation-name: slidein;
  /* Standard syntax*/
  animation-duration: 1s;
  animation-name: slidein;

  a {
    text-decoration: none !important;
    color: white !important;
    text-align: center !important;

    span {
      color: white !important;
      font-family: sans-serif !important;
      font-weight: bold !important;
      text-align: center !important;

      .FontSmaller {
        display: $display-none;
      }
    }
  }

  span.hasProducts {
    text-align: center;
    display: block;
    padding: 5px 10px 10px;
  }

  img.FloatLeft,
  span.fa.fa-shopping-cart::before {
    display: $display-none;
  }
}
/* Animation */
@-webkit-keyframes slidein {
  from {
    margin-top: -30px;
  }

  to {
    margin-top: 0;
  }
}
@keyframes slidein {
  from {
    margin-top: -30px;
  }

  to {
    margin-top: 0;
  }
}