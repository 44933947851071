.CrossellingCount {
  margin: 30px 0 50px 0 !important;

  tbody {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
    justify-items: center;

    tr {
      td {
        padding: 0 !important;
        border: 0 !important;
      }
    }

    td {
      display: grid !important;
      grid-gap: 10px;
      word-break: break-word;
      width: 100% !important;
      justify-items: center;
    }
  }

  .CrossellingImageArea {
    img {
      max-height: 100px !important;
    }
  }

  .CrossellingInfoArea {
    text-align: center !important;

    span.AdditionalInfo {
      font-size: 9px !important;
      display: $display-none;
    }

    .Price {
      text-align: center !important;
      float: none !important;
      margin: 5px 0 !important;

      .price-value {
        font-weight: normal !important;
      }
    }

    .FloatRight {
      float: none !important;
    }

    .ButtonBasket {
      display: inline-block;
    }

    .Weight.TaxAndShippingInfo {
      display: $display-none;
    }
  }
}
@media screen and (max-width: 600px) {
  /* Crosselling and variations grid */
  .CrossellingCount {
    tbody {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
}