/* Cookie */
.message-bar.message-cookies {
  z-index: 1000 !important;
  background-color: #1d1d1d !important;
  color: white !important;
  padding: 0 !important;
  border: 0 !important;

  .message-bar-inner {
    padding: 24px 10px 38px 10px !important;
    min-height: unset !important;
    max-width: 800px !important;
    margin: 0 auto !important;
    text-align: center !important;

    a {
      color: white !important;
      font-weight: bold;
    }

    .message-bar-button {
      background: white !important;
      color: black !important;
    }
  }
}