.SiteMap {
  .SiteMapContainer {
    .ProductListHead {
      .InnerHead {
        .SiteMapLink {
          display: $display-none;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .SiteMap {
    .SiteMapLeft,
    .SiteMapRight {
      float: none !important;
      width: 100% !important;
    }
  }
}