.BasketBox {
  margin-left: 0;

  .FontSmaller {
    display: $display-none;
  }

  .basket-icon-link {
    span.fa.fa-shopping-cart {
      font-size: 14px !important;
      line-height: 2 !important;
      text-align: right !important;
    }
  }

  span {
    position: relative !important;
    font-size: inherit !important;
    line-height: 2 !important;
    font-family: inherit !important;

    img {
      display: $display-none;
    }
  }

  span:last-child {
    &::before {
      content: '\f290' !important;
      font-family: 'FontAwesome' !important;
      margin-right: 6px !important;
      font-size: 18px !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .NavBarTop.HorizontalNavBar {
    .BasketBox.NavElementIcon {
      span {
        font-size: 0 !important;
      }
    }
  }
}