.NavBarBottom.HorizontalNavBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* left */
  .PropertyContainer.custom-navbarbottom-left {
    .SizeContainer {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
      flex-wrap: wrap !important;
    }
  }
  /* center */
  .PropertyContainer.custom-navbarbottom-center {
    .SizeContainer {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      flex-wrap: wrap !important;
    }
  }
  /* right */
  .PropertyContainer.custom-navbarbottom-right {
    .SizeContainer {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-end !important;
      flex-wrap: wrap !important;
    }
  }
  /* divide */
  .PropertyContainer.custom-navbarbottom-divide {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;

    .SizeContainer {
      width: auto !important;
    }

    .custom-container {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap !important;
    }

    .custom-left {
      justify-content: flex-start !important;
    }

    .custom-right {
      justify-content: flex-end !important;
    }
  }

  .PropertyContainer {
    margin: 0 auto !important;

    .SizeContainer {
      > div {
        margin: 10px 0;
      }

      .BoxContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 !important;

        .ContextBoxHead {
          display: $display-none;
        }

        .ContextBoxBody {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          padding: 0 !important;
          margin: 0 !important;
          align-items: stretch;

          .InputField,
          .InputLabelling {
            display: $display-none;
          }
        }

        form {
          .ContextBoxBody {
            > a {
              display: $display-none;
            }
          }

          .InputLabelling {
            margin: 0 !important;
            padding: 0 !important;
          }

          input {
            margin: 0 !important;
            padding: 9px 16px !important;
            width: 320px;
          }
        }
      }

      .CreditCards {
        img {
          max-width: 100% !important;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .NavBarBottom.HorizontalNavBar {
    .BoxContainer {
      .ContextBoxBody {
        flex-direction: column;
      }

      form {
        input {
          max-width: 230px;
        }
      }
    }

    .ep-js {
      button {
        margin: 0 !important;
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .NavBarBottom.HorizontalNavBar {
    .custom-navbarbottom-divide {
      justify-content: center !important;
    }

    .custom-navbarbottom-center,
    .custom-navbarbottom-divide,
    .custom-navbarbottom-left,
    .custom-navbarbottom-right {
      .SizeContainer {
        justify-content: center !important;
      }
    }

    .custom-navbarbottom-divide {
      .custom-left,
      .custom-right {
        margin: 0 auto !important;
      }
    }

    .custom-navbarbottom-divide {
      .custom-container {
        width: 100% !important;
        text-align: center !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .NavBarBottom.HorizontalNavBar {
    .PropertyContainer.custom-navbarbottom-divide {
      justify-content: flex-start !important;
    }
  }
}