.ContentArea {
  h1 {
    margin-bottom: 20px !important;
  }

  h2 {
    margin-bottom: 18px !important;
  }

  h3 {
    margin-bottom: 16px !important;
  }

  h4 {
    margin-bottom: 14px !important;
    font-size: 22px;
  }

  h5 {
    margin-bottom: 12px !important;
    font-size: 20px;
  }

  h6 {
    margin-bottom: 10px !important;
    font-size: 18px;
  }

  p {
    margin-bottom: 10px !important;
    line-height: 1.5 !important;
  }
}
/* Font awesome */
.fa {
  font-style: normal !important;
  font-family: FontAwesome !important;

  .fa-shopping-cart:before {
    font-family: FontAwesome !important;
    margin: 0 0.5em;
  }
}