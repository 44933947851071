/* Shopping cart */
#BasketForm {
  margin: 32px 0 48px 0 !important;

  > div.Basket {
    input#CouponCode {
      float: none !important;
    }

    #BasketTable {
      font-weight: bold !important;

      > tbody {
        td {
          border: 0 !important;
        }

        > tr:last-child {
          td {
            border-top: 0 !important;
            background: #e2e2e2 !important;
          }

          td:nth-child(1) {
            padding-left: 10px !important;
          }
        }
      }
    }

    > table.BlankTable.CouponTable {
      > tbody {
        > tr {
          > td.AlignMiddle.PaddingRight {
            display: $display-none;
          }
        }
      }
    }
  }
}

.Message.Medium.FreeShipping {
  background: #f1f1f1 !important;
  padding: 32px !important;
  margin-bottom: 32px;

  h3 {
    font-size: 20px !important;
  }

  span {
    font-size: 14px !important;
  }
}
/* Coupon */
.CouponTable {
  .DialogMessage.MessageWarningSmall {
    background: none !important;
    padding-left: 10px !important;
    margin: 10px 0 !important;
  }

  h3 {
    font-weight: bold !important;
    padding: 0 !important;
    margin-bottom: 0;
  }

  tbody {
    tr {
      display: flex !important;
      flex-direction: column !important;
    }
  }

  #RedeemCouponButton {
    display: block !important;
    float: none !important;
    margin: 0 !important;

    > input {
      margin: 0 !important;
    }
  }
}

input.CouponCode {
  margin-left: 0 !important;
}
@media screen and (min-width: 500px) {
  /* Input */
  #BasketTable {
    > tbody {
      > tr.SeparateRow.ProductItem {
        > td {
          > input.ep-js.ep-uiInput.ep-uiInput-text.ep-uiValidate {
            border-radius: 3px !important;
            box-sizing: border-box;
            padding-left: 10px !important;
            box-shadow: 2px 2px 2px #f1f1f1;
            width: 40px !important;
          }
        }
      }
    }
  }

  table.Basket,
  table.MiniBasket,
  table.MiniStatusBasket {
    td {
      padding: 6px 0 !important;
    }
  }

  .Basket {
    .productBasketImage {
      td {
        padding: 1px !important;
      }
    }
  }
}
/* Basket icons */
/* Delete item icon */
.Basket {
  .Icon.TrashIcon {
    background: none !important;
    width: auto !important;
    height: auto !important;

    &::before {
      content: '\f00d' !important;
      font-family: 'FontAwesome' !important;
      font-size: 14px !important;
      color: #444040 !important;
    }
  }

  button[name="Delete"] {
    padding: 0 !important;
  }
}
/* Shopping list icon */
button[name="AddBasketToShoppingList"] {
  span.ShoppingListIcon {
    background: none !important;
    height: auto !important;
    width: auto !important;
  }
}
/* Responsive */
@media screen and (max-width: 500px) {
  #BasketForm {
    .Basket {
      font-size: 12px !important;
      padding: 1px !important;
      font-weight: normal !important;

      input[name="Quantity"] {
        width: 16px !important;
        display: block !important;
        font-size: 12px !important;
        font-weight: normal !important;
      }

      .productBasketImage .ThumbnailsInBasket {
        display: $display-none;

        td.AlignMiddle {
          a {
            word-break: break-word !important;
          }
        }
      }

      #BasketTable {
        tbody {
          td {
            font-size: 12px !important;
            padding: 1px !important;
            font-weight: normal !important;

            a {
              font-size: 12px !important;
              padding: 1px !important;
              font-weight: normal !important;
            }
          }

          td.SeparateColumn.Money {
            b {
              font-size: 13px !important;
            }
          }
        }
      }
    }
  }

  .CheckoutButtons {
    #CheckOutTop {
      button {
        padding: 6px 12px !important;
      }
    }

    #KCOButton {
      #KCOLink {
        padding: 6px 12px !important;
      }
    }

    #ContinueShoppingButton {
      a {
        padding: 6px 0 !important;
      }
    }
  }

  .CouponTable {
    #RedeemCouponButton {
      input[name="Save"] {
        padding: 6px 12px !important;
      }
    }
  }

  .OrderProcessNavigation {
    #CheckOut {
      button {
        padding: 6px 12px !important;
      }
    }
  }

  button[name="AddBasketToShoppingList"] {
    padding: 6px 12px 6px 7px !important;
  }
}
@media screen and (max-width: 400px) {
  #BasketForm {
    .Basket {
      font-size: 11px !important;

      tbody {
        td {
          font-size: 11px !important;

          a {
            font-size: 11px !important;
          }
        }
      }

      input[name="Quantity"] {
        width: 12px !important;
      }
    }
  }
}
/* Traditional shopping process */
@media screen and (max-width: 600px) {
  table.Basket {
    td {
      word-break: break-all;
      hyphens: auto;
    }

    .Money {
      white-space: normal;
    }

    input#CouponCode {
      width: 95px;
    }

    .ShippingOptionContainer {
      .HalfWidth {
        width: 100% !important;
        padding-bottom: 20px;
      }
    }
  }

  table.productBasketImage {
    tr {
      td {
        padding: 0;
      }
    }
  }

  .BasketSharing {
    width: 100%;
    float: left;

    .InputBlock {
      display: block !important;

      .BS-inputLabel,
      .InputField {
        width: 100%;
        float: left;
        display: block;
        padding: 0;
      }
    }

    textarea.BasketSharing-textarea {
      width: 300px !important;
      font-size: 75% !important;
    }

    .Dialog {
      padding: 0;
    }
  }

  textarea.BasketComment {
    width: 70%;
  }

  table#BasketTable {
    select.basketPaymentSelect,
    select.basketShippingSelect {
      width: 100% !important;
    }
  }

  ul.OrderProcess {
    li {
      width: 75px;
      height: 65px;
    }

    i.Icon {
      zoom: 60%;
    }
  }

  span.ShowElement.ExplainItem {
    display: $display-none;
  }
}