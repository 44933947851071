/* Styles for subpage lists */
.CategoryList {
  > .CategoryList {
    overflow: hidden !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.ListItemCategory {
  margin: 10px !important;
  min-height: auto !important;
  padding: 20px !important;
  border: $border;
  border-radius: $border-radius;
  cursor: pointer;
  transition: $transition;

  &:hover {
    box-shadow: $box-shadow-card;
  }

  .ImageArea {
    float: none !important;
    text-align: left !important;
    min-height: auto !important;
    min-width: auto !important;
    margin: 0 !important;

    img {
      margin: 0 !important;
    }
  }

  .InfoArea {
    h3 {
      a {}
    }
  }
}
@media screen and (max-width: 900px) {
  .ListItemCategory {
    tr {
      td {
        display: block !important;
        width: 100% !important;
      }
    }
  }
}
@media (min-width: 500px) {
  .CategoryList {
    > .CategoryList {
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    }
  }
}
@media (min-width: 900px) {
  .CategoryList {
    > .CategoryList {
      grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
    }
  }
}