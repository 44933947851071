.BlogListItem {
  h2[itemprop="name headline"] {
    line-height: unset !important;
    margin: 8px 0;

    a {
      font-weight: bold !important;
      font-size: 28px !important;
    }
  }

  .Box,
  .CategoryImage,
  .Message .MessageBlock img {
    display: $display-none;
  }

  .PublishInfo {
    font-size: 12px !important;
    width: 100% !important;
    display: inline-block !important;
    margin: 0 auto 20px auto !important;
    text-align: left !important;
  }

  .Message {
    > a {
      display: inline-block !important;
      margin-top: 20px;
      padding: 8px 16px;
      border: 1px solid #1d1d1d;
      font-size: 14px !important;
      text-transform: uppercase !important;
      font-weight: normal !important;
      letter-spacing: 1px !important;
    }

    a[name="MainText"] {
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
    }

    .CategoryImage {
      display: block !important;
      margin: 0 10px 0 0 !important;
      float: none !important;

      a {
        margin-top: 0 !important;
        padding: 0 !important;
        border: 0 !important;
      }
    }

    .MessageBlock a {
      text-decoration: underline;
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
    }
  }

  div[itemprop="description articleBody text"] {
    .Message {
      a {
        margin: 0 !important;
        padding: 0 !important;
        border: none !important;
      }
    }
  }
}

.BlogListItem[itemprop="blogPost"] {
  .Message {
    .MessageBlock {
      height: auto !important;
      max-height: 150px !important;
    }
  }
}

.custom-blog {
  .ContentArea {
    max-width: 800px !important;
    margin: 0 auto !important;

    h1[itemprop="name"] {
      text-align: center !important;
      font-size: 42px !important;
    }

    .BlogListItem {
      margin: 30px auto !important;
      padding: 20px !important;
      border-bottom: $border !important;

      h1[itemprop="name headline"] {
        text-align: center !important;
        font-size: 42px !important;
      }
    }

    #blogWriteCommentForm .ShoppingListIcon,
    a.CustomButton ins {
      display: $display-none;
    }

    .Message {
      img,
      p {
        margin-bottom: 1.69rem !important;
      }
    }

    .DialogMessage {
      padding: 10px !important;
      margin: 30px 0 10px 0 !important;
      background: none !important;
      background-color: #fff8dd !important;

      p {
        margin-bottom: 0 !important;
      }
    }

    a.CustomButton {
      height: auto !important;
      background: none !important;
      border-radius: 0 !important;
      padding: 8px 16px !important;
    }

    .breadcrumb-parent-wrapper {
      text-align: center !important;

      .BreadcrumbItem,
      .BreadcrumbLastItem {
        font-size: 12px !important;
      }
    }

    .ContentAreaWrapper {
      div {
        > .Pager {
          display: $display-none;
        }

        .Pager~.Pager {
          display: block !important;
        }
      }
    }
  }

  div[itemprop="comment"] {
    border-bottom: 1px solid #dfdfdf !important;
    margin: 30px 0 !important;

    .Box.ForumPlainItemPosterContainerInner {
      border: 0 !important;
      margin-bottom: 0 !important;
      padding: 0 !important;
      letter-spacing: 1px !important;
      font-size: 12px !important;

      b[itemprop="creator"] span {
        text-transform: uppercase !important;
        font-weight: normal !important;
      }
    }
  }

  #blogSpamForm {
    .SpamLink {
      font-size: 12px !important;
    }
  }
}

.custom-blog.custom-blog-article {
  .ContentArea {
    max-width: 800px !important;
    margin: 0 auto !important;

    .BlogListItem {
      margin: 30px auto !important;
      padding: 0 !important;
      border: 0 !important;

      .PublishInfo {
        text-align: center !important;
        margin: 20px auto !important;
      }
    }
  }
}

.custom-rss-icon {
  float: none !important;
  display: block !important;
  margin: 30px 0 !important;
}

#blogWriteCommentForm {
  margin-top: 40px !important;
  padding-top: 20px !important;

  .ForumPostInputContainer,
  legend {
    padding: 0 !important;
  }

  legend {
    margin-bottom: 10px !important;
  }

  .ForumPostInputContainer {
    margin-bottom: 20px !important;
  }

  .Dialog,
  fieldset {
    padding: 0 !important;
    margin: 0 !important;
  }

  .Dialog {
    margin: 20px 0 !important;
  }

  .TableHead {
    div {
      margin: 0 !important;
      font-weight: bold !important;
      font-size: 20px !important;
    }
  }

  .ForumPostInputContainer {
    .ForumPostSubjectInput {
      margin: 0 !important;
    }
  }
}

.BlogComment {
  padding: 20px 0 !important;
  margin: 0 !important;

  .Bold {
    margin-bottom: 10px !important;
  }
}