.Header {
  /* left */
  .PropertyContainer.custom-header-left {
    .SizeContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      float: none !important;

      > div {
        padding: 6px 10px !important;
      }
    }
  }
  /* center */
  .PropertyContainer.custom-header-center {
    .SizeContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      > div {
        padding: 6px 10px !important;
      }
    }
  }
  /* right */
  .PropertyContainer.custom-header-right {
    .SizeContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      float: none !important;

      > div {
        padding: 6px 10px !important;
      }
    }
  }
  /* divide */
  .PropertyContainer.custom-header-divide {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;

    .SizeContainer {
      width: auto !important;

      > div {
        padding: 6px 10px !important;
      }
    }
  }

  .PropertyContainer {
    height: auto !important;
    margin: 0 auto !important;

    .SizeContainer {
      .ShopLogo {
        padding: 10px 0;
      }
      /* Shop name and slogan */
      .ShopNameSlogan {
        .ShopName {
          font-family: Arial, sans-serif !important;
          font-size: 58px !important;
          font-weight: bold !important;

          a {
            font-family: Arial, sans-serif !important;
            font-size: 58px !important;
            font-weight: bold !important;
          }
        }

        .ShopSlogan {
          margin-top: 0 !important;
          font-family: Arial, sans-serif !important;
          font-size: 18px !important;

          p {
            margin-top: 0 !important;
            font-family: Arial, sans-serif !important;
            font-size: 18px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 430px) {
  .Header.HorizontalNavBar {
    .PropertyContainer {
      justify-content: center !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .Header.HorizontalNavBar {
    .PropertyContainer.custom-header-left,
    .PropertyContainer.custom-header-right {
      .SizeContainer {
        justify-content: center !important;
      }
    }

    .PropertyContainer.custom-header-divide {
      .custom-container {
        width: auto !important;
        text-align: center !important;
      }
    }

    .PropertyContainer {
      .SizeContainer {
        .BasketBox.NavElementIcon {
          span {
            font-size: 0 !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 959px) {
  .Header.HorizontalNavBar {
    .PropertyContainer.custom-header-center {
      .SizeContainer {
        flex-wrap: nowrap !important;
        justify-content: space-between !important;
      }
    }
  }
}