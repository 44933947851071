/* Product list card */
.ProductListImageBox {
  tbody {
    display: grid !important;
    overflow: hidden !important;

    td {
      width: auto !important;
      display: grid !important;
      justify-items: $pc-justify;
      grid-gap: 10px;
      position: relative !important;

      .ImageArea {
        position: relative !important;
        margin: 0 auto !important;
        width: 100% !important;

        a {
          position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;

          img {
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            max-height: 100% !important;
          }
        }
      }

      .InfoArea {
        display: grid !important;
        grid-gap: $pc-infoarea-gap;
        padding: 0 !important;
        text-align: $pc-text-align;
        width: 100%;

        .Description {
          p {
            margin-bottom: 0 !important;
          }
        }

        h3:first-of-type {
          margin-bottom: 0 !important;
        }

        .BasketIcon {
          display: $display-none;
        }

        br {
          display: $display-none;
        }

        .Price {
          text-align: $pc-text-align;
        }

        .ClearBoth {
          text-align: $pc-text-align;
        }

        .LinkArea {
          opacity: $pc-smalltext-opacity !important;

          .FloatLeft {
            text-align: $pc-text-align;
            float: none !important;
          }

          .ClearBoth {
            font-size: 0.7em !important;
          }
        }

        .TopPaddingWide {
          margin: 0 !important;
          padding: 0 !important;

          a {
            text-align: $pc-text-align;
            font-weight: bold !important;
            display: inline-block !important;
            max-width: 240px !important;
          }
        }

        .FontSmaller {
          font-size: 0.7em !important;
          opacity: $pc-smalltext-opacity !important;
        }

        .ProductNo {
          font-size: 0.7em !important;
          opacity: $pc-smalltext-opacity !important;
        }

        .Weight {
          opacity: $pc-smalltext-opacity !important;
        }

        .TextWeak {
          display: block !important;
        }
      }
    }
  }
}