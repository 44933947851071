.menu-container {
  width: 100% !important;
  margin: 0 auto;
  text-align: left;
  background: transparent;

  .menu {
    > ul {
      margin: 0 auto;
      width: 100%;
      list-style: none;
      padding: 0;
      position: relative;
      box-sizing: border-box;

      &::after,
      &::before {
        content: "/f146";
        font-family: "FontAwesome";
        display: none;
        cursor: pointer;
        float: right;
        padding: 1.5em 2em;
        background: #fff;
        color: #333;
      }

      &::after {
        clear: both;
      }

      > li {
        float: left;
        background: transparent;
        padding: 0;
        margin: 0;

        &:hover {
          background: transparent;
        }

        a {
          text-decoration: none;
          padding: 10px;
          display: block;
          color: inherit;
        }

        > ul {
          display: none;
          width: 100%;
          background: white;
          padding: 15px 20px !important;
          position: absolute;
          z-index: 99;
          left: 0;
          margin: 0;
          list-style: none;
          box-sizing: border-box;

          &::after,
          &::before {
            content: "";
            display: table;
          }

          &::after {
            clear: both;
          }

          > li {
            margin: 0;
            padding-bottom: 0;
            list-style: none;
            width: 25%;
            background: none;
            float: left;

            a {
              padding: 0.2em 0;
              width: 95%;
              display: block;
              font-size: 14px;
              font-weight: bold;
              color: #1d1d1d !important;
              overflow: hidden;
              text-overflow: ellipsis;

              &:hover {
                font-weight: bold !important;
              }
            }

            > ul {
              display: block;
              padding: 0;
              margin: 10px 0 0;
              list-style: none;
              box-sizing: border-box;

              &::after,
              &::before {
                content: "";
                display: table;
              }

              &::after {
                clear: both;
              }

              > li {
                float: left;
                width: 100%;
                padding: 2px;
                margin: 0;
                font-size: 0.8em;

                a {
                  border: 0;
                  font-weight: normal;

                  &:hover {
                    font-weight: normal !important;
                  }
                }
              }
            }
          }
        }

        ul.normal-sub {
          width: 300px;
          left: auto;
          padding: 10px 20px;

          > li {
            width: 100%;

            a {
              border: 0;
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}

.Header.HorizontalNavBar.header-contains-menu {
  .menu-container.custom-mobile-menu {
    width: 100% !important;
  }
}

#menuDesktopPosition {
  padding: 0 !important;
  margin: 0 !important;
}

.menu-mobile {
  display: none;
  padding: 10px;

  &::after {
    content: "\f0c9";
    font-family: "FontAwesome";
    font-size: 17px !important;
    padding: 0;
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-25%);
  }
}

.menu-dropdown-icon {
  &::before {
    content: "\f105";
    font-family: "FontAwesome";
    display: none;
    cursor: pointer;
    float: right;
    padding: 1.5em 2em;
    background: transparent;
    color: #333;
  }
}
@media screen and (min-width: 960px) {
  .menu-container {
    .menu {
      > ul {
        > li {
          > ul {
            border-radius: $border-radius;
            box-shadow: $box-shadow;
          }
        }
      }
    }
  }

  .custom-navbartop-center {
    .menu-container {
      width: 100% !important;

      .menu {
        width: 100% !important;

        > ul.HorizontalDisplay {
          text-align: center !important;
          display: flex !important;
          flex-wrap: wrap !important;
          justify-content: center !important;
        }

        > ul {
          > li {
            display: inline-block !important;
            float: none !important;

            > ul {
              width: 100% !important;
              text-align: left !important;
            }

            > ul.normal-sub {
              width: 300px !important;
              text-align: left !important;

              > li {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }

  .custom-navbartop-right {
    .menu-container {
      width: 100% !important;

      .menu {
        width: 100% !important;

        > ul.HorizontalDisplay {
          text-align: right !important;
        }

        > ul {
          > li {
            display: inline-block !important;
            float: none !important;

            > ul {
              width: 100% !important;
              text-align: left !important;
            }

            > ul.normal-sub {
              width: 300px !important;
              text-align: left !important;

              > li {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }

  .custom-header-center {
    position: relative !important;

    .SizeContainer {
      display: flex !important;
      align-items: center !important;

      .menu {
        > ul {
          position: unset !important;
        }
      }
    }

    .menu-container {
      width: auto !important;
    }
  }

  .custom-header-start.custom-header-divide {
    position: relative !important;

    .SizeContainer {
      display: flex !important;
      align-items: center !important;

      .menu {
        > ul {
          position: unset !important;
        }
      }
    }

    .menu-container {
      width: auto !important;
    }
  }

  .custom-header-left {
    .menu-container {
      width: auto !important;
    }
  }

  .custom-header-right {
    .menu-container {
      width: auto !important;
    }
  }

  .Header.HorizontalNavBar {
    .menu-container {
      max-width: 550px !important;
    }
  }
}
@media screen and (min-width: 1000px) {
  .Header.HorizontalNavBar {
    .menu-container {
      max-width: 600px !important;
    }
  }
}
@media screen and (min-width: 1100px) {
  .Header.HorizontalNavBar {
    .menu-container {
      max-width: 700px !important;
    }
  }
}
@media screen and (min-width: 1200px) {
  .Header.HorizontalNavBar {
    .menu-container {
      max-width: 800px !important;
    }
  }
}
@media only screen and (max-width: 959px) {
  .menu-container {
    width: 100% !important;
    text-align: left;

    .menu {
      .show-on-mobile {
        display: flex!important;
        background: white !important;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: 10px !important;
      }

      > ul {
        display: none;

        > li {
          width: 100%;
          float: none;
          display: block;

          a {
            width: auto;
            display: block;
            font-size: inherit;
            font-weight: bold;
            color: #1d1d1d !important;
            background: white !important;
          }

          > ul {
            position: relative;

            > li {
              float: none;
              width: 100%;
              margin-top: 20px;

              > ul {
                position: relative;

                > li {
                  float: none;
                }
              }
            }

            li:first-child {
              margin: 0;
            }
          }

          ul.normal-sub {
            width: 100%;

            > li {
              a {
                padding: 4px 0;
              }
            }
          }
        }

        > li.menu-dropdown-icon {
          &::before {
            order: 2;
            padding: 0.5em 2em 0.5em 0 !important;
            font-weight: bold;
          }
        }
      }
    }
  }

  .menu-mobile {
    display: block !important;
  }

  .menu-dropdown-icon:before {
    display: block;
    padding: 16px 32px 16px 0;
  }

  .Header.HorizontalNavBar {
    .menu-mobile {
      font-size: 0 !important;
      display: inline-block !important;
    }
  }
}