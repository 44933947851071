/* NavBarTop */
.NavBarTop.HorizontalNavBar {
  /* right */
  .PropertyContainer.custom-navbartop-right {
    .SizeContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }

    .menu-container {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
    }
  }
  /* left */
  .PropertyContainer.custom-navbartop-left {
    .SizeContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
  }
  /* center */
  .PropertyContainer.custom-navbartop-center {
    .SizeContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .menu-container {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
  }
  /* divide */
  .PropertyContainer.custom-navbartop-divide {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;

    .SizeContainer {
      width: auto !important;
    }
  }

  .PropertyContainer {
    height: auto !important;
    margin: 0 auto !important;

    .SizeContainer {
      float: none !important;
      height: auto !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .NavBarTop.HorizontalNavBar {
    .PropertyContainer.custom-navbartop-divide {
      justify-content: center !important;

      .SizeContainer {
        justify-content: center !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .NavBarTop.HorizontalNavBar {
    .PropertyContainer.custom-navbartop-divide {
      justify-content: flex-start !important;
    }
  }
}
@media screen and (max-width: 959px) {
  .NavBarTop.HorizontalNavBar {
    .PropertyContainer.custom-navbartop-center,
    .PropertyContainer.custom-navbartop-divide,
    .PropertyContainer.custom-navbartop-right {
      .menu-container {
        display: inline-block !important;
      }
    }

    .PropertyContainer.custom-navbartop-divide {
      .SizeContainer {
        width: 100% !important;
        display: inline-block !important;
      }
    }
  }
}