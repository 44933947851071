$site-width: 1200px;
$page-width: 700px;
$checkout-width: 1000px !important;
$display-none: none !important;
/* box shadows */
$box-shadow: 0 0 20px 5px rgba(0,0,0,0.1);
$box-shadow-small: 0 0 10px 0 rgba(0,0,0,0.1);
$box-shadow-card: 0 0 10px 0 rgba(0,0,0,0.08);
/* borders */
$border: 1px solid #f1f1f1;
$border-radius: 5px;
$border-radius-small: 3px;
$transition: 100ms ease-in-out;
/* product card settings */
$pc-text-align: left !important;
$pc-justify: flex-start !important;
$pc-infoarea-gap: 2px;
$pc-smalltext-opacity: 1;