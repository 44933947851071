/* Styles for guestbook */
#ForumPostSpamForm,
#GuestBookSpamForm,
#blogSpamForm {
  .SpamLink {
    color: black !important;
  }
}

.ForumTreeItemContainer {
  margin-bottom: 16px !important;
}

.ForumPostMessageContainer {
  padding-left: 0 !important;
}

#GuestBookAddThreadForm {
  .TableHead {
    div {
      margin: 32px 0 16px 0 !important;
      font-size: 20px !important;
      font-weight: bold;
    }
  }

  .Dialog {
    padding-left: 0 !important;

    fieldset {
      padding-left: 0 !important;
    }
  }

  .DialogButtonbar {
    button {
      span {
        display: $display-none;
      }
    }
  }
}