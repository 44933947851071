/* Breadcrumbs */
.ContentArea {
  .BreadcrumbItem {
    opacity: 0.5 !important;
    font-weight: normal !important;
    text-decoration: none !important;
    font-size: 14px !important;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;

    &:hover {
      opacity: 1 !important;
      background: none !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    &::after {
      content: '\f0da' !important;
      font-family: 'FontAwesome' !important;
      margin: 0 5px !important;
    }
  }

  .BreadcrumbLastItem {
    font-size: 14px !important;
    font-weight: bold !important;
  }
}

.breadcrumb-parent-wrapper {
  .BreadcrumbItem {
    opacity: 0.5 !important;
    font-weight: normal !important;
    text-decoration: none !important;
    font-size: 14px !important;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;

    &:hover {
      opacity: 1 !important;
      background: none !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    &::after {
      content: '\f0da' !important;
      font-family: 'FontAwesome' !important;
      margin: 0 5px !important;
    }
  }

  .BreadcrumbLastItem {
    font-size: 14px !important;
    font-weight: bold !important;
  }
}