/* Single column */
.CategoryProducts {
  .HotDealList {
    .ListItemProductContainer {
      .ListItemProduct {
        .InfoArea {
          padding: 20px 10px !important;
          margin: 0 !important;

          .ImageArea {
            max-width: 100px !important;
          }

          .ListItemProductInfoContainer {
            table.HorizontalDisplay.FullSize {
              tbody {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                td.AlignTop {
                  flex: 1;

                  .AlignRight,
                  .PriceArea {
                    text-align: left!important;
                  }
                }

                td.Links {
                  flex: 1;
                  width: 100%;
                }

                td.Links.AlignRight {
                  text-align: left!important;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 600px) {
  /* Single column */
  .CategoryProducts {
    .HotDealList {
      .ListItemProductContainer {
        .ListItemProduct {
          .InfoArea {
            .ListItemProductInfoContainer {
              table.HorizontalDisplay.FullSize {
                tbody {
                  flex-direction: row;
                  align-items: center;

                  td.AlignTop {
                    .AlignRight,
                    .PriceArea {
                      text-align: right!important;
                    }
                  }

                  td.Links {
                    width: auto;

                    button.AddToBasketButton {
                      width: auto;
                    }
                  }

                  td.Links.AlignRight {
                    text-align: center!important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}