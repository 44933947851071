.ProductPage {
  .ProductDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 32px 0;

    .InfoArea {
      float: none !important;
      width: auto !important;
      flex: 1;
      order: 2;
      margin: 8px !important;

      .PriceContainer {
        padding: 32px !important;
        border-radius: $border-radius;

        .custom-basket-input-button {
          .ep-uiSpinner-unit {
            line-height: 2.5 !important;
          }
        }

        .Price {
          text-align: left !important;
          margin-bottom: 10px !important;
        }

        #KCOButton {
          #KCOLink {
            padding: 8px 16px !important;
          }
        }
      }

      .AddToBasketForm {
        .custom-basket-input-button {
          display: flex;
          flex-direction: row;
        }
      }

      .AdditionalLinks.New {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        gap: 5px;
        margin-bottom: 0;

        > li {
          width: auto !important;
          border: $border !important;
          padding: 0 !important;
          border-radius: $border-radius;

          a.LinkIcon,
          input.LinkIcon {
            padding: 10px !important;
            background: none !important;
          }
        }

        #CompareProductForm {
          a,
          input,
          input {
            background: none !important;
            padding: 4px 6px !important;
            font-weight: normal !important;
            font-size: 13px !important;
          }
        }
      }

      .epGetEmailNotifikation {
        border: $border !important;
        border-radius: $border-radius;
        background: none !important;
        padding: 10px !important;
        display: inline-block;
      }
    }

    .ProductImage {
      flex: 1;
      order: 1;
      min-width: 300px;
      margin: 8px !important;
    }

    .SocialMedia {
      flex: 1;
      order: 3;
      flex-basis: 100%;
      width: 100% !important;
    }
  }
  /* Product tabs */
  #ProductInfoTabs {
    border: $border;
    padding: 32px !important;
    border-radius: $border-radius;

    .ui-tabs-panel {
      border-top: 0 !important;
    }

    .ui-tabs-nav {
      .ui-state-active {
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: 1px solid #1d1d1d !important;
        background: none !important;
      }

      li {
        a {
          padding: 10px 14px !important;
        }
      }
    }

    #ProductRatings {
      .Box[itemprop="reviews"] {
        padding: 16px !important;
        margin-top: 8px !important;

        .TopPaddingWide {
          margin-top: 16px !important;
          padding: 0 !important;
        }
      }

      .FloatLeft[itemprop="aggregateRating"] {
        margin: 8px 0;
        opacity: 0.7;
        font-size: 13px;
      }
    }
  }
}
/* Browse other categories */
.AlsoBrowseThisCat {
  max-width: 900px !important;
  display: inline-block;
  font-size: 13px;
  border: $border;
  border-radius: $border-radius;
  padding: 20px;
  margin-top: 20px;

  a {
    line-height: 1.8 !important;
    font-size: 13px;
    font-weight: normal;

    &:hover {
      font-weight: normal;
    }
  }
}
/* Product image modal */
html {
  body {
    .ep-uiLightbox {
      border-radius: 0 !important;
      box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.3) !important;

      .ep-uiSlides-ctrl a,
      .ep-uiSlides-ctrl:hover a:hover {
        color: white !important;
        background: #1d1d1d !important;
        border: 1px solid #1d1d1d !important;
        opacity: 1 !important;
        box-shadow: none !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px !important;
        box-sizing: border-box !important;
        width: 27px !important;
        height: 27px !important;
      }

      .ep-uiLightbox-ctrlClose {
        background: #1d1d1d !important;
        border: 1px solid #1d1d1d !important;
        box-shadow: none !important;
        /* Product image zoom */
        .ep-uiMagnifier-boxSelect {
          border-radius: 50% !important;
        }

        .ep-uiMagnifier-zoom {
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3) !important;
          border-radius: 50% !important;
        }
      }
    }
  }
}
/* Recommend product */
.ListItemProduct {
  .InfoArea.ProductDetail {
    .ImageArea {
      img.ProductSmallImage {
        max-width: 300px !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  /* HIDE PRODUCT ZOOM ON MOBILE */
  .ep-uiLightbox-showThumbs .ep-uiLightbox-thumbs,
  .ep-uiMagnifier-zoom {
    display: $display-none;
  }

  .ep-uiLightbox-showThumbs {
    .ep-uiLightbox-slides {
      right: 0 !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .ProductPage {
    div[itemprop="mainEntity"] > h2,
    .ProductDetails .InfoArea h1[itemprop="name"] {
      text-align: center !important;
    }
  }
}
@media screen and (max-width: 500px) {
  #ProductInfoTabs,
  .ProductDetails .PriceContainer {
    padding: 20px !important;
  }
}