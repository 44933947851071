#ContentAreaFix,
#NavBarLeftFix,
#NavBarRightFix,
.ContentArea:before,
.NavBarLeft:before,
.NavBarRight:before,
img {
  height: auto !important;
}
/* Backgrounds */
.Footer,
.Header,
.Middle,
.NavBarBottom,
.NavBarRoof {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media screen and (max-width: 1000px) {
  .Footer,
  .Header,
  .NavBarRoof,
  .NavBarTop {
    padding: 0 !important;
  }
}