/* Shopping process */
.MultipleStep-Container {
  max-width: 100% !important;
  box-shadow: none !important;

  .CheckOut {
    margin: 32px auto !important;
  }

  .Header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 18px auto;

    .Logo {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      float: none !important;
      max-width: unset !important;
      margin: 8px !important;
    }

    .Continue {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      float: none !important;
      margin: 8px !important;
    }
  }

  .CheckOutNav {
    padding: 0 10% !important;
  }

  .ep-contentbox-item,
  .summary {
    border-radius: 0 !important;
  }
}
@media screen and (max-width: 500px) {
  .MultipleStep-Container {
    .Header {
      flex-direction: column;
    }

    .CheckOutNav {
      padding: 0 !important;
    }
  }
}