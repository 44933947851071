/* Sidebar styles */
.Middle.sidebar-right-active {
  .ContentArea {
    margin-right: 251px !important;
    margin-left: 0 !important;
  }
}

.Middle.sidebar-left-active {
  .ContentArea {
    margin-left: 251px !important;
    margin-right: 0 !important;
  }
}

.Middle.sidebar-right-active.sidebar-left-active {
  .ContentArea {
    margin-left: 251px !important;
    margin-right: 251px !important;
  }
}

.sidebar-toggle-wrapper {
  display: $display-none;
}

.sidebar-left,
.sidebar-right {
  .SizeContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > * {
      margin: 10px 0 !important;
    }
  }
}

.NavBarLeft {
  .ContextBoxHead,
  .ShopNameHead {
    padding: 0 0 0 5px !important;
  }

  .jstree-closed {
    .jstree-leaf {
      .jstree-icon {
        display: $display-none;
      }
    }
  }

  .jstree-leaf {
    .jstree-icon {
      display: $display-none;
    }
  }

  .jstree-open {
    .jstree-leaf {
      .jstree-icon {
        display: $display-none;
      }
    }
  }

  .CategoriesBox {
    .BoxContainer {
      .ContextBoxHead {
        span {
          margin-bottom: 8px !important;
        }
      }
    }
  }
}

.NavBarRight {
  .ContextBoxHead,
  .ShopNameHead {
    padding: 0 0 0 5px !important;
  }

  .jstree-closed {
    .jstree-leaf {
      .jstree-icon {
        display: $display-none;
      }
    }
  }

  .jstree-leaf {
    .jstree-icon {
      display: $display-none;
    }
  }

  .jstree-open {
    .jstree-leaf {
      .jstree-icon {
        display: $display-none;
      }
    }
  }
}

.GeneralLayout {
  .jstree-closed .jstree-closed .jstree-icon,
  .jstree-closed .jstree-icon,
  .jstree-closed .jstree-open .jstree-icon,
  .jstree-open .jstree-closed .jstree-icon,
  .jstree-open .jstree-icon,
  .jstree-open .jstree-open .jstree-icon {
    background: none !important;
    width: auto !important;
    height: auto !important;
    top: 0 !important;
    padding: 0 !important;
  }

  .jstree-closed .jstree-closed .jstree-icon::before,
  .jstree-closed .jstree-icon::before,
  .jstree-open .jstree-closed .jstree-icon::before {
    content: '\f0da' !important;
    font-family: 'FontAwesome' !important;
  }

  .jstree-closed .jstree-open .jstree-icon::before,
  .jstree-open .jstree-icon::before,
  .jstree-open .jstree-open .jstree-icon::before {
    content: '\f0d7' !important;
    font-family: 'FontAwesome' !important;
  }
}
@media screen and (max-width: 800px) {
  .sidebar-toggle-wrapper {
    display: flex !important;
    justify-content: space-between;
    font-size: 18px !important;
    margin: 0 !important;
    transition: 50ms;
    position: fixed !important;
    top: 50% !important;
    z-index: 999 !important;
    width: 100% !important;
  }

  .sidebar-left,
  .sidebar-right {
    display: $display-none;
  }

  .sidebar-left.sidebar-left-open,
  .sidebar-right.sidebar-right-open {
    display: block !important;
    position: fixed !important;
    top: 0 !important;
    box-shadow: -1px 0 10px 1px rgba(0, 0, 0, 0.1) !important;
    z-index: 999;
    height: 100vh;
    overflow-y: scroll !important;
  }

  .sidebar-left.sidebar-left-open {
    left: 0 !important;
  }

  .sidebar-right.sidebar-right-open {
    right: 0 !important;
  }

  .sidebar-left-toggle {
    text-align: left !important;
    color: inherit;
    flex: 1;

    &::before {
      background: white;
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3) !important;
      border-radius: 0 50px 50px 0;
      padding: 5px 5px 5px 2px;
    }
  }

  .sidebar-right-toggle {
    text-align: right !important;
    color: inherit;
    flex: 1;

    &::before {
      background: white;
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3) !important;
      border-radius: 50px 0 0 50px;
      padding: 5px 2px 5px 5px;
    }
  }

  .sidebar-toggle-wrapper.sidebar-only-menu.sidebar-second-menu .sidebar-left-toggle::before,
  .sidebar-toggle-wrapper.sidebar-only-menu.sidebar-second-menu .sidebar-left-toggle::before,
  .sidebar-toggle-wrapper.sidebar-second-menu .sidebar-left-toggle::before,
  .sidebar-toggle-wrapper.sidebar-second-menu .sidebar-right-toggle.sidebar-toggle-open::before {
    content: '\f138' !important;
    font-family: 'FontAwesome' !important;
  }

  .sidebar-toggle-wrapper.sidebar-only-menu.sidebar-second-menu .sidebar-left-toggle.sidebar-toggle-open::before,
  .sidebar-toggle-wrapper.sidebar-only-menu.sidebar-second-menu .sidebar-right-toggle::before,
  .sidebar-toggle-wrapper.sidebar-second-menu .sidebar-left-toggle.sidebar-toggle-open::before,
  .sidebar-toggle-wrapper.sidebar-second-menu .sidebar-right-toggle::before {
    content: '\f137' !important;
    font-family: 'FontAwesome' !important;
  }

  .sidebar-toggle-wrapper.sidebar-only-menu .sidebar-left-toggle.sidebar-toggle-open::before,
  .sidebar-toggle-wrapper.sidebar-only-menu .sidebar-left-toggle::before,
  .sidebar-toggle-wrapper.sidebar-only-menu .sidebar-right-toggle.sidebar-toggle-open::before,
  .sidebar-toggle-wrapper.sidebar-only-menu .sidebar-right-toggle::before {
    content: '\f0c9' !important;
    font-family: 'FontAwesome' !important;
  }

  .sidebar-toggle-wrapper.sidebar-only-menu .sidebar-left-toggle.sidebar-toggle-open::before,
  .sidebar-toggle-wrapper.sidebar-only-menu .sidebar-left-toggle::before {
    padding: 8px 8px 8px 4px;
  }

  .sidebar-toggle-wrapper.sidebar-only-menu .sidebar-right-toggle.sidebar-toggle-open::before,
  .sidebar-toggle-wrapper.sidebar-only-menu .sidebar-right-toggle::before {
    padding: 8px 4px 8px 8px;
  }

  .Middle.sidebar-left-active {
    .ContentArea {
      margin-left: 0 !important;
    }
  }

  .Middle.sidebar-right-active {
    .ContentArea {
      margin-right: 0 !important;
    }
  }

  .Middle.sidebar-right-active.sidebar-left-active {
    .ContentArea {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  #ContentAreaFix,
  .Div .ContentArea:before {
    background: transparent !important;
  }

  .sidebar-left .PropertyContainer,
  .sidebar-right .PropertyContainer {
    padding-top: 10px !important;
  }

  .sidebar-left-close,
  .sidebar-right-close {
    padding: 10px;
    display: inline-block;
    margin: 2px;
    width: 90%;
  }

  .sidebar-left-close {
    text-align: right;
  }

  .sidebar-right-close {
    text-align: left;
  }

  .sidebar-left-close::before,
  .sidebar-right-close::before {
    content: '\f00d';
    font-family: 'FontAwesome';
    font-size: 22px;
  }
}