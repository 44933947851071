/* NavBarRoof */
.NavBarRoof.HorizontalNavBar {
  /* divide */
  .PropertyContainer.custom-navbarroof-divide {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;

    .SizeContainer {
      width: auto !important;
    }
  }
  /* left */
  .PropertyContainer.custom-navbarroof-left {
    .SizeContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      float: none !important;

      > div {
        padding: 4px 10px !important;
      }
    }
  }
  /* right */
  .PropertyContainer.custom-navbarroof-right {
    .SizeContainer {
      float: none !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      > div {
        padding: 4px 10px !important;
      }
    }
  }
  /* center */
  .PropertyContainer.custom-navbarroof-center {
    .SizeContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      > div {
        padding: 4px 10px !important;
      }
    }
  }

  .PropertyContainer.custom-navbarroof-start {
    .SizeContainer {
      > div {
        padding: 4px 10px !important;
      }
    }
  }

  .PropertyContainer {
    height: auto !important;
    box-sizing: border-box !important;
    margin: 0 auto !important;

    .SizeContainer {
      p,
      p.BasketLink,
      td {
        padding: 0 !important;
      }

      p.BasketLink {
        height: auto !important;
        line-height: normal !important;
      }

      .fa-shopping-cart:before {
        margin-right: 4px !important;
      }

      .LocaleFlags {
        top: 0 !important;
      }

      .NavigationElement {
        height: auto !important;
        line-height: normal !important;

        > a,
        > em > a {
          padding: 0 !important;
          height: auto !important;
          line-height: normal !important;
        }

        a:after {
          content: "" !important;
          margin-left: 0 !important;
          color: transparent !important;
        }
      }

      .NavigationElement.SSLSwitch {
        input {
          padding: 0 !important;
          height: auto !important;
          line-height: normal !important;
        }
      }
    }

    .custom-left,
    .custom-right {
      float: none !important;
      top: unset !important;
    }

    .custom-right {
      right: unset !important;
    }

    .custom-left {
      left: unset !important;
    }
  }
}
@media screen and (max-width: 600px) {
  /* Roof divide centering */
  .custom-navbarroof-start.custom-navbarroof-divide {
    flex-direction: column !important;
  }

  .custom-navbarroof-center,
  .custom-navbarroof-left,
  .custom-navbarroof-right {
    .SizeContainer {
      justify-content: center !important;
    }
  }

  .custom-navbarroof-divide {
    .custom-left,
    .custom-right {
      margin: 0 auto !important;
      text-align: center !important;
    }
  }
}