.ProductListImageBox.three-colums-product-list {
  tbody {
    grid-gap: 10px;
    row-gap: 20px;
    padding: 5px;

    td {
      padding: 10px;

      .ImageArea {
        max-width: 200px !important;
        max-height: 200px !important;
        height: 200px !important;
        line-height: 0 !important;
      }

      .InfoArea {
        .AddToBasketForm {
          display: inline-block !important;
          width: 100%;

          .NoWrap.custom-basket-input-button,
          .ShowInlineElement .custom-basket-input-button {
            display: flex !important;
            flex-direction: row !important;
            margin-top: 12px !important;

            button {
              margin-top: 0 !important;
            }
          }

          .AddToBasketButton {
            width: calc(100% - 55px);
          }
        }

        .AddToBasketButton {
          width: 99%;

          .BasketIcon {
            display: $display-none;
          }
        }
      }
    }

    td:not(.transparent-background) {
      border: $border;
      border-radius: $border-radius;
      transition: $transition;
    }
  }
}

.CategoryList .CategoryProducts .HotDealList.SearchFacetsActive .ProductListImageBox.three-colums-product-list tbody,
.Middle.sidebar-left-active .CategoryList .CategoryProducts .HotDealList .ProductListImageBox.three-colums-product-list tbody,
.Middle.sidebar-right-active .CategoryList .CategoryProducts .HotDealList .ProductListImageBox.three-colums-product-list tbody,
.Middle.sidebar-right-active.sidebar-left-active .CategoryList .CategoryProducts .HotDealList .ProductListImageBox.three-colums-product-list tbody,
.Middle.sidebar-right-active.sidebar-left-active .RemoteSearchResults .HotDealList .ProductListImageBox.three-colums-product-list tbody,
.RemoteSearchResults .ProductListImageBox.three-colums-product-list tbody {
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
}
/* Left active & Right active */
.Middle.sidebar-right-active.sidebar-left-active {
  .CategoryList {
    .CategoryProducts {
      .HotDealList {
        .ProductListImageBox.three-colums-product-list {
          tbody {
            td {
              .ImageArea {
                max-width: 180px !important;
                max-height: 180px !important;
                height: 180px !important;
              }
            }
          }
        }
      }
    }
  }
}
/* Search results */
.RemoteSearchResults {
  .ProductListImageBox.three-colums-product-list tbody td .ImageArea {
    max-width: 100px !important;
    max-height: 100px !important;
    height: 100px !important;
  }
}
@media (min-width: 500px) {
  .CategoryList {
    .CategoryProducts {
      .HotDealList.SearchFacetsActive {
        .ProductListImageBox.three-colums-product-list {
          tbody {
            grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
          }
        }
      }
    }
  }
  /* Left active */
  .Middle.sidebar-left-active {
    .CategoryList {
      .CategoryProducts {
        .HotDealList {
          .ProductListImageBox.three-colums-product-list {
            tbody {
              grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
            }
          }
        }
      }
    }
  }
  /* Right active */
  .Middle.sidebar-right-active {
    .CategoryList {
      .CategoryProducts {
        .HotDealList {
          .ProductListImageBox.three-colums-product-list {
            tbody {
              grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
            }
          }
        }
      }
    }
  }
  /* Left and Right active */
  .Middle.sidebar-right-active.sidebar-left-active {
    .CategoryList {
      .CategoryProducts {
        .HotDealList {
          .ProductListImageBox.three-colums-product-list {
            tbody {
              grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
            }
          }
        }
      }
    }

    .RemoteSearchResults {
      .HotDealList {
        .ProductListImageBox.three-colums-product-list {
          tbody {
            grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
          }
        }
      }
    }
  }

  .RemoteSearchResults {
    .ProductListImageBox.three-colums-product-list {
      tbody {
        grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
      }
    }
  }
}
@media (min-width: 650px) {
  .ProductListImageBox.three-colums-product-list {
    tbody {
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      grid-gap: 10px;
      row-gap: 20px;
    }
  }
}
@media (min-width: 700px) {
  .ProductListImageBox.three-colums-product-list {
    tbody {
      td {
        .ImageArea {
          max-width: 300px !important;
          max-height: 300px !important;
          height: 300px !important;
        }
      }
    }
  }
}
@media (min-width: 801px) {
  .ProductListImageBox.three-colums-product-list {
    tbody {
      td:not(.transparent-background) {
        &:hover {
          box-shadow: $box-shadow-card;
        }
      }
    }
  }

  .Middle.sidebar-right-active.sidebar-left-active {
    .CategoryList {
      .CategoryProducts {
        .HotDealList {
          .ProductListImageBox.three-colums-product-list {
            tbody {
              grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            }
          }
        }
      }
    }

    .RemoteSearchResults {
      .HotDealList {
        .ProductListImageBox.three-colums-product-list {
          tbody {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
          }
        }
      }
    }
  }

  .CategoryList {
    .CategoryProducts {
      .HotDealList.SearchFacetsActive {
        .ProductListImageBox.three-colums-product-list {
          tbody {
            td {
              .ImageArea {
                max-width: 160px !important;
                max-height: 160px !important;
                height: 160px !important;
              }
            }
          }
        }
      }
    }
  }

  .Middle.sidebar-left-active {
    .CategoryList {
      .CategoryProducts {
        .HotDealList {
          .ProductListImageBox.three-colums-product-list {
            tbody {
              td {
                .ImageArea {
                  max-width: 160px !important;
                  max-height: 160px !important;
                  height: 160px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .Middle.sidebar-right-active {
    .CategoryList {
      .CategoryProducts {
        .HotDealList {
          .ProductListImageBox.three-colums-product-list {
            tbody {
              td {
                .ImageArea {
                  max-width: 160px !important;
                  max-height: 160px !important;
                  height: 160px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 900px) {
  .ProductListImageBox.three-colums-product-list {
    tbody {
      grid-gap: 10px;
      row-gap: 20px;
    }
  }

  .RemoteSearchResults {
    .ProductListImageBox.three-colums-product-list {
      tbody {
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      }
    }
  }
}
@media (min-width: 1000px) {
  .Middle.sidebar-right-active.sidebar-left-active {
    .CategoryList {
      .CategoryProducts {
        .HotDealList {
          .ProductListImageBox.three-colums-product-list {
            tbody {
              grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
            }
          }
        }
      }
    }

    .RemoteSearchResults {
      .HotDealList {
        .ProductListImageBox.three-colums-product-list {
          tbody {
            grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
          }
        }
      }
    }
  }

  .CategoryList {
    .CategoryProducts {
      .HotDealList.SearchFacetsActive {
        .ProductListImageBox.three-colums-product-list {
          tbody {
            td {
              .ImageArea {
                max-width: 220px !important;
                max-height: 220px !important;
                height: 220px !important;
              }
            }
          }
        }
      }
    }
  }

  .Middle.sidebar-left-active {
    .CategoryList {
      .CategoryProducts {
        .HotDealList {
          .ProductListImageBox.three-colums-product-list {
            tbody {
              td {
                .ImageArea {
                  max-width: 220px !important;
                  max-height: 220px !important;
                  height: 220px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .Middle.sidebar-right-active {
    .CategoryList {
      .CategoryProducts {
        .HotDealList {
          .ProductListImageBox.three-colums-product-list {
            tbody {
              td {
                .ImageArea {
                  max-width: 220px !important;
                  max-height: 220px !important;
                  height: 220px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}