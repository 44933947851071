/* General e-mail form */
.epCustomFormWrapper {
  dl {
    dt {
      float: none !important;
      width: 100% !important;
      text-align: left !important;

      label {
        display: inline-block !important;
        margin-bottom: 8px !important;
      }
    }

    dd {
      margin: 0 !important;

      .ep-uiInput-text,
      textarea {
        max-width: 600px;
      }
    }

    .AlignRight {
      text-align: left !important;
    }
  }
}
/* Log in page */
.log-in-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  row-gap: 20px;

  form,
  ul.NoListStyleType {
    border: $border;
    border-radius: $border-radius;
    padding: 25px !important;
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .log-in-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-gap: 20px;
    row-gap: 0;
  }
}
/* Log in form */
#UserLoginForm {
  h2.MarginBottom {
    display: $display-none;
  }

  .InputBlock {
    margin-bottom: 20px !important;

    .InputLabelling {
      margin-bottom: 8px !important;
    }

    .InputField {
      input {
        width: 300px !important;
        padding: 6px !important;
      }

      input[name="Password"] {
        margin-bottom: 16px !important;
      }
    }
  }
}
/* Register form */
.NewCustomer {
  #registrationForm {
    .AddressDialog {
      border: none !important;

      div.InputLabelling,
      td.InputLabelling {
        padding: 0 !important;
        width: auto !important;
      }
    }

    .Dialog {
      div.InputField,
      td.InputField {
        padding: 0 !important;
      }

      div.InputLabelling {
        float: none !important;
        text-align: left !important;
        margin-bottom: 4px !important;
      }

      div.InputField {
        float: none !important;
        margin-bottom: 8px !important;
      }

      .MandatoryField {
        .InputField {
          color: #888888 !important;
          font-size: 12px !important;
          font-style: italic;
        }
      }
    }

    .NewsletterForm {
      margin: 32px 0 0 0 !important;
    }

    .TableHead {
      div {
        line-height: unset !important;
        margin: 32px 0 16px 0 !important;
        font-weight: bold;
        font-size: 20px !important;
      }
    }

    .InputBlock {
      .InputField {
        input {
          width: 100% !important;
          max-width: 360px !important;
        }

        input[type="checkbox"] {
          width: auto !important;
          max-width: unset !important;
        }
      }
    }
  }

  .BreadcrumbItem {
    margin-right: 8px !important;
  }
}
/* Recommend product form */
.ListItemProduct {
  .ListItemProductInfoContainer {
    .NewProduct {
      display: $display-none;
    }
  }
}

#ProductRecommendationForm {
  .Dialog {
    padding: 0 !important;

    .FormGrid {
      tr {
        display: flex !important;
        flex-direction: column;

        td {
          input {
            width: 100% !important;
            max-width: 360px !important;
          }
        }
      }
    }
  }

  .TableHead {
    div {
      margin: 0 0 32px 0 !important;
      font-size: 20px !important;
      font-weight: bold !important;
    }
  }
}
/* Product inquiry form */
#ProductInquiryForm {
  .TableHead {
    div {
      margin: 0 0 32px 0 !important;
      font-size: 20px !important;
      font-weight: bold !important;
    }
  }

  .Dialog {
    padding: 0 !important;

    .InputBlock {
      display: flex !important;
      flex-direction: column;

      .InputLabelling {
        float: none !important;
        text-align: left !important;
        margin-bottom: 4px;
      }

      .InputField {
        float: none !important;
        margin-bottom: 8px;

        input,
        textarea {
          width: 99% !important;
          max-width: 360px !important;
        }
      }
    }
  }
}

#ProductRatingForm {
  .Box[itemprop="reviews"] {
    padding: 16px !important;
  }

  .Box {
    .BottomMargin[itemprop="reviewBody"] {
      margin-top: 16px !important;
    }
  }

  .FloatLeft[itemprop="aggregateRating"] {
    margin: 8px 0;
    opacity: 0.7;
    font-size: 13px;
  }

  h3 {
    font-size: 20px;

    i.Icon.ProductRatingEditIcon {
      display: $display-none;
    }

    strong.LeftSmallPadding {
      padding: 0 !important;
    }
  }

  .ListItemProductInfoContainer {
    .AlignRight,
    .PriceArea {
      text-align: left !important;
    }
  }

  .ListItemProduct {
    .InfoArea.ProductDetail {
      border: 0 !important;

      .ImageArea {
        img.ProductSmallImage {
          max-width: 300px !important;
        }
      }
    }
  }

  .InputBlock {
    .InputField {
      padding: 0 !important;
      margin-bottom: 4px;
    }
  }
}
/* Rate product form */
#NewRatingTable {
  margin-bottom: 48px;

  .PaddingTop {
    opacity: 0.8;
    max-width: 640px;
    font-size: 13px;
    margin: 8px 0 16px;

    a {
      font-size: 13px !important;
      font-weight: bold !important;
    }
  }

  .FloatRight {
    float: none !important;
    text-align: left !important;
  }

  .Dialog {
    .InputBlock {
      .InputField {
        .ep-uiInput {
          width: 99% !important;
          max-width: 500px !important;
        }
      }
    }

    div.InputField {
      float: none !important;
      text-align: left !important;
      padding: 0 !important;
    }

    td.InputField {
      padding: 0 !important;
    }

    div.InputLabelling {
      float: none !important;
      text-align: left !important;
    }
  }

  .Box {
    border: 0 !important;
  }

  .ProductRatingSwitch input {
    width: auto !important;
  }

  .SizeContainer {
    min-width: unset !important;
  }

  .InputBlock {
    margin: 4px 0;

    .InputLabelling {
      padding: 0 !important;
      margin-bottom: 4px;
      font-weight: bold !important;
    }

    .InputField {
      .TopMargin {
        margin: 0 0 4px 0 !important;
        font-weight: bold !important;
      }

      span.AlignMiddle {
        display: block !important;
        opacity: 0.7;
        font-size: 13px;
      }
    }
  }

  .ProductRatingIEMarginBugFix {
    margin-top: 16px;
  }
}
@media screen and (max-width: 500px) {
  #UserLoginForm {
    .InputBlock {
      .InputField {
        input {
          width: auto !important;
        }
      }
    }
  }
}