#ViewUserShoppingListForm {
  i.Icon.NewIconSmall {
    display: $display-none;
  }

  .Icon.TrashIcon {
    background: none !important;
    width: auto !important;
    height: auto !important;

    &::before {
      content: '\f00d' !important;
      font-family: 'FontAwesome' !important;
      font-size: 14px !important;
      color: #444040 !important;
    }
  }

  .ShoppingList {
    tbody {
      tr {
        td {
          vertical-align: middle !important;

          button {
            color: #1d1d1d !important;
          }
        }

        td.SeparateColumn {
          button {
            color: #1d1d1d !important;
          }
        }
      }
    }

    .AlignCenter {
      button {
        background: white !important;
      }
    }

    .FloatLeft {
      float: none !important;

      button[name="new"] {
        background: #1d1d1d !important;
        color: white !important;
        border: 1px solid #1d1d1d !important;
        padding: 3px 8px !important;
      }
    }
  }
}

#ShoppingListForm {
  .Basket {
    tbody {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      grid-gap: 20px;

      input[name="Quantity"] {
        border-radius: 3px !important;
        box-sizing: border-box;
        padding-left: 10px !important;
        box-shadow: 2px 2px 2px #f1f1f1;
        width: 40px !important;

        th {
          display: $display-none;
        }

        tr {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
          align-items: center;
          border-bottom: $border;
          padding-bottom: 20px;

          td {
            width: auto !important;

            img {
              float: none !important;
            }
          }
        }

        tr.HideElement {
          display: $display-none;
        }

        tr:first-child {
          border-bottom: 0 !important;
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  #ShoppingListForm {
    .Basket {
      tbody {
        grid-gap: 40px;

        tr {
          text-align: center !important;
          justify-content: center !important;
          grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

          td {
            text-align: center !important;

            img {
              padding: 0 !important;
            }

            button.BasketIcon {
              display: $display-none;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  #ViewUserShoppingListsForm {
    .FloatLeft button[name="new"] {
      margin: 10px 0 !important;
    }
  }
}