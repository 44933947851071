/* Bug fix */
@media screen and (max-width: 600px) {
  .no-sidebars {
    .RemoteSearchFacets+div,
    .RemoteSearchFacets~.CategoryList {
      overflow: visible !important;
    }
  }
}

.CategoryList {
  .CategoryProducts {
    .HotDealList.SearchFacetsActive {
      .ProductListImageBox {
        div.InfoArea {
          overflow: visible !important;
        }
      }
    }
  }
}

.FacetsToggle {
  a {
    i {
      display: $display-none;
    }
  }
}

.RemoteSearchFacet.RemoteSearchFacetPrice {
  max-width: 180px !important;
}
/* Styles for product search results */
.RemoteSearchResults {
  .NewProduct {
    display: $display-none;
  }
}

.RemoteSearchFacetsInitial {
  text-align: center !important;
  padding: 0;
}

.FacetsToggle {
  display: $display-none;
}
/* Remote search facet styling */
/* Remove box shadow */
.RemoteSearchFacets {
  box-shadow: none !important;

  .RemoteSearchFacet {
    margin: 0 0 20px 0 !important;

    ul li > label,
    ul li > label.Bold,
    ul li a > label,
    ul li a > span,
    ul li label.Bold > span {
      font-weight: lighter !important;
      font-size: 14px !important;
    }

    label.Bold,
    label.Bold span,
    ul li label.Bold > span {
      font-weight: bold !important;
    }

    > ul {
      padding-left: 2px !important;
    }

    ul {
      li {
        margin: 5px 0 !important;

        ul.LeftPaddingWide {
          padding-left: 8px !important;
        }
      }
    }

    .ui-slider-horizontal {
      margin: 10px 17px 15px 8px !important;
    }
  }

  .RemoteSearchFacet.RemoteSearchFacetPrice {
    .ui-slider {
      span.ui-slider-handle {
        background: #1d1d1d !important;
        height: 16px !important;
        border-radius: 50%;
        top: -5px !important;

        &:hover {
          background: #1d1d1d !important;
        }
      }

      .ui-slider-range {
        background: #1d1d1d !important;
      }
    }

    button {
      padding: 3px 10px !important;
    }
  }

  .FacetName {
    font-weight: bold !important;
    font-size: 18px !important;
    display: inline-block !important;
    word-break: break-word !important;
  }
}
/* Search facet rating input width */
.RemoteSearchFacetRating {
  input {
    width: 0 !important;
  }
}
@media screen and (max-width: 600px) {
  div#RemoteSearchFacets.Collapsed {
    height: 40px;
    overflow: hidden;
  }

  div#RemoteSearchFacets {
    height: auto;
    width: 100% !important;
    overflow: hidden !important;
  }

  .FacetsToggle {
    display: block;

    a {
      border: 1px solid;
      padding: 5px;
      margin: 10px 0;
      display: block;
      font-size: 14px !important;
      text-align: center;
    }
  }

  .CategoryProducts.show-facets {
    .FloatRight.MarginBottom {
      float: none !important;
    }
  }
}
@media (min-width: 900px) {
  .Middle {
    .ContentAreaWrapper {
      > .RemoteSearchFacets {
        margin-right: 50px !important;
      }

      .CategoryList {
        > .RemoteSearchFacets {
          margin-right: 50px !important;
        }
      }
    }
  }
}