.LoginButton,
.ep-uiInput-button,
button,
ul.NoListStyleType a.Action {
  border-radius: $border-radius-small !important;
  transition: $transition !important;

  &:hover {
    opacity: 0.8 !important;
  }
}

.MultipleStep-Container {
  .Continue {
    #ContinueShoppingButton {
      transition: $transition !important;
      background: white !important;
      color: #1d1d1d !important;
      border: 1px solid #1d1d1d !important;
      display: inline-block !important;
    }
  }
}

.NavBarBottom.HorizontalNavBar {
  .ep-js {
    button {
      padding: 8px 16px !important;
      font-size: 14px !important;
      margin: 0 !important;
      line-height: 1.3 !important;
    }
  }
}

#ProductInfoTabs {
  #ProductRatings {
    button.WriteProductRating {
      padding: 8px 16px !important;
      transition: $transition !important;
      display: inline-block !important;
      background: none !important;
      margin: 0 !important;
      border: 1px solid #000000;
      background-color: white;
    }
  }
}

button[name="AddBasketToShoppingList"] {
  font-weight: normal !important;
  font-size: 14px !important;
  border: 1px solid #1d1d1d !important;
  padding: 8px 16px 8px 10px !important;
  margin-top: 10px !important;
}

#BasketForm .RefreshIconSmall,
.AddToBasketButton .BasketIcon,
.NotSubscribedIcon,
.SubscribedIcon {
  display: $display-none;
}

.OrderProcessNavigation {
  #CheckOut {
    button {
      font-size: 14px !important;
      transition: $transition !important;
    }
  }
}

ul.NoListStyleType {
  a.Action {
    line-height: 140% !important;
    font-weight: normal !important;
    display: inline-block !important;
    padding: 8px 16px !important;
    transition: $transition !important;
    background: white !important;
    color: #1d1d1d !important;
    border: 1px solid #1d1d1d !important;

    &:hover {
      opacity: 0.8 !important;
    }
  }
}

.CheckoutButtons {
  #KCOButton {
    #KCOLink {
      font-weight: normal !important;
      font-size: 14px !important;
      display: inline-block !important;
      padding: 8px 16px !important;
      transition: $transition !important;
      background: white !important;
      color: #1d1d1d !important;
      border: 1px solid #1d1d1d !important;
    }
  }

  #ContinueShoppingButton {
    a {
      font-weight: normal !important;
      line-height: 140% !important;
      display: inline-block !important;
      transition: $transition !important;
    }
  }

  #CheckOutTop {
    button {
      font-size: 14px !important;
      transition: $transition !important;
    }
  }

  #ContinueShoppingButton {
    a {
      font-size: 14px !important;
    }
  }
}

#UserLoginForm {
  button[type=submit] {
    font-weight: normal !important;
    line-height: 140% !important;
    display: inline-block !important;
    transition: $transition !important;

    &:hover {
      opacity: 0.8 !important;
    }
  }
}

form {
  .Box {
    span.ep-uiDatepicker-showButton {
      background: transparent !important;
    }

    button {
      span.SearchIcon {
        display: $display-none;
      }
    }
  }
}
/* SEARCH FACET BUTTONS */
.RemoteSearchFacets {
  .RemoteSearchFacetPrice {
    button {
      padding: 10px !important;
      transition: $transition !important;
    }
  }
}

.RemoteSearchFacetsInitial {
  a.RemoteSearchFacetsReset {
    margin-left: 0 !important;
    padding: 6px 0 !important;
    margin: 10px 0;
    font-size: 13px !important;
    text-align: left !important;
    transition: $transition !important;
    display: inline-block !important;
  }
}

.ProductPage {
  .CrossellingInfoArea {
    .ButtonBasket {
      padding: 0 !important;
      background: none !important;
      color: inherit !important;
      text-decoration: underline !important;

      &:hover {
        border-color: transparent !important;
      }
    }
  }
}

.VariationsTable {
  .AddToBasketForm {
    button.ButtonBasket {
      padding: 0 !important;
      background: none !important;
      color: inherit !important;
      text-decoration: underline !important;

      &:hover {
        border-color: transparent !important;
      }
    }
  }
}

.ProductDetails,
.ProductListImageBox .InfoArea .AddToBasketForm .ShowInlineElement .custom-basket-input-button,
.VariationsTable {
  .epWidth50.epWidth-4 {
    width: 30px !important;
  }
}

.AddToBasketForm {
  .ep-uiSpinner-stepper {
    border-radius: 3px !important;

    .ep-uiSpinner-stepDown,
    .ep-uiSpinner-stepUp {
      line-height: 14px !important;

      &:hover {
        color: white !important;
        opacity: 0.7 !important;
      }
    }
  }
}

.custom-basket-input-button {
  .ep-uiInput {
    margin: 0 !important;
  }

  .ep-uiSpinner-stepper {
    margin: 0 2px !important;
    height: auto !important;
  }

  a.ep-uiSpinner-stepDown,
  a.ep-uiSpinner-stepUp {
    font-size: 12px !important;
  }
}
/* Focus-state box shadow */
button:focus,
input:focus,
input:focus+.ep-uiInput,
input:focus+.ep-uiInput-custom .ep-uiInput {
  box-shadow: none !important;
}