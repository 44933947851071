/* VariationsTable */
.VariationsTable {
  margin: 30px 0 50px 0 !important;

  .AddToBasketForm {
    .Quantity,
    select[name="Quantity"] {
      display: $display-none;
    }

    .ShowInlineElement {
      div.custom-variationstable-input-button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        input,
        span {
          display: $display-none;
        }
      }
    }
  }
}

.VariationsTable.custom-alternative-products {
  margin-bottom: 50px !important;
}

.custom-alternative-products.custom-variations-first {
  margin-bottom: 50px !important;

  .Price {
    font-weight: normal !important;
  }

  tbody {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    align-items: stretch;

    tr:first-of-type {
      grid-column: 1 / -1;
      margin-bottom: 32px !important;
    }

    tr {
      th {
        background: white !important;
        font-size: 28px !important;
      }
    }
  }

  .Wrapper {
    .DisplayInlineBlock {
      display: $display-none;
    }
  }

  .QuarterSize {
    justify-self: center !important;
    width: 100% !important;

    .Wrapper {
      img {
        max-height: 100px !important;
      }
    }

    > .Wrapper {
      display: grid !important;
      grid-gap: 5px;
      height: 100%;
      text-align: center;

      > .Wrapper {
        font-weight: bold !important;
      }

      * {
        align-self: end;
      }
    }
  }
}
/* Not custom variations */
.VariationsTable:not(.custom-variations-first) {
  > tbody {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;

    tr {
      display: grid;
      align-items: stretch;
      justify-items: center;

      td {
        border: 0 !important;
        padding: 0 !important;
      }
    }

    > tr:last-of-type,
    > tr:first-child {
      display: $display-none;
    }

    .productBasketImage {
      a {
        display: inline-block !important;
        text-align: center;
      }

      .ThumbnailsInBasket {
        display: block !important;
        margin: 10px auto !important;
        max-height: 100px !important;
        max-width: 100px !important;
        float: none !important;
      }

      tr {
        td {
          line-height: 20px !important;
        }
      }
    }

    .price-value {
      margin: 5px 0 !important;
      display: inline-block !important;
      font-weight: normal !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .VariationsTable:not(.custom-variations-first) > tbody,
  .custom-alternative-products.custom-variations-first tbody {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .custom-alternative-products.custom-variations-first {
    tr:first-of-type {
      justify-self: center;
    }
  }
}