/* Vanilla sticky footer bug fix */
.FooterContainer,
.FooterContainer.sticky {
  position: relative !important;
}

.Footer {
  /* center */
  .PropertyContainer.custom-footer-center {
    .SizeContainer {
      justify-content: space-between !important;
    }
  }
  /* left */
  .PropertyContainer.custom-footer-left {
    .SizeContainer {
      justify-content: flex-start !important;
    }
  }
  /* right */
  .PropertyContainer.custom-footer-right {
    .SizeContainer {
      justify-content: flex-end !important;
    }
  }
  /* divide */
  .PropertyContainer.custom-footer-divide {
    .custom-container {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap !important;
    }

    .custom-left {
      justify-content: flex-start !important;
    }

    .custom-right {
      justify-content: flex-end !important;
    }
  }

  .PropertyContainer {
    margin: 0 auto !important;

    .SizeContainer {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;

      ul {
        list-style-type: none !important;
      }

      > ul {
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
      }

      > div {
        display: flex !important;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 32px 16px;

        > div {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: left !important;

          h1,
          h2,
          h3,
          h4,
          h5 {
            margin-bottom: 24px;
          }

          p {
            padding: 0 !important;
          }

          > ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            > li {
              text-align: left !important;
            }
          }
        }
      }
      /* Log in and sign up for newsletter forms */
      .ContextBoxHead {
        padding: 0 !important;

        span {
          font-weight: bold !important;
          margin-bottom: 8px;
        }

        h1,
        span {
          height: auto !important;
          padding: 0 !important;
        }
      }

      .ContentBox {
        .ContextBoxBody {
          .Entry {
            min-height: unset !important;
            margin: 0 !important;
            padding: 0 !important;
          }

          strong {
            color: inherit !important;
          }
        }
      }
      /* Last seen products */
      .ProductLastViewedBox {
        max-width: 440px !important;

        .de_epages-catalogUiProductLastViewed {
          display: flex !important;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          margin-top: 8px !important;

          li {
            min-height: unset !important;
            padding: 0 !important;
            margin: 4px;

            a {
              display: flex !important;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;
            }
          }

          img {
            float: none !important;
            width: auto !important;
          }
        }
      }
      /* CategoryBox styling */
      .CategoriesBox {
        .ContextBoxHead {
          margin-bottom: 12px !important;
        }

        .ContextBoxBody {
          padding: 0 !important;

          ul {
            li {
              margin: 0 0 8px 0 !important;
            }
          }

          em {
            a {
              display: inline-block;
              margin-bottom: 8px;
            }
          }

          .FloatLeft.RightMargin {
            float: none !important;
            margin: 0 0 12px 0 !important;
          }
        }
      }
    }

    .SizeContainer.custom-container.custom-right {
      width: auto !important;
    }

    .SizeContainer.custom-container.custom-left {
      padding: 0 !important;
      width: auto !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .Footer {
    .PropertyContainer.custom-footer-divide {
      justify-content: center !important;
    }

    .PropertyContainer.custom-footer-center,
    .PropertyContainer.custom-footer-divide,
    .PropertyContainer.custom-footer-left,
    .PropertyContainer.custom-footer-right {
      .SizeContainer {
        justify-content: center !important;
      }
    }

    .PropertyContainer.custom-footer-divide {
      .custom-container {
        width: 100% !important;
        text-align: center !important;
      }
    }

    .PropertyContainer.custom-footer-divide {
      .custom-left,
      .custom-right {
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Footer {
    .PropertyContainer.custom-footer-divide {
      flex-direction: column !important;
    }

    .PropertyContainer.custom-footer-divide {
      .custom-container {
        flex-direction: column !important;
        justify-content: flex-start !important;
      }
    }
  }
}
@media screen and (max-width: 860px) {
  .Footer.HorizontalNavBar {
    .PropertyContainer.custom-footer-center {
      .SizeContainer {
        > div {
          justify-content: center !important;
          align-items: center !important;
          text-align: center !important;
        }

        .BoxContainer {
          align-items: center !important;
          text-align: center !important;

          .ContextBoxBody {
            text-align: center !important;
          }
        }
      }
    }

    .PropertyContainer {
      .SizeContainer {
        flex-direction: column;

        > div {
          flex-direction: column;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  /* Basic footer styling */
  .Footer {
    .ContextBoxBody {
      text-align: left !important;
    }

    .NavigationText {
      > div {
        > div {
          ul {
            li {
              text-align: left !important;
            }
          }
        }
      }
    }
  }
}