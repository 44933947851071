/* Hot Deal List Styles */
.HotDealList {
  margin: 32px 0;

  .ListItemProductContainer {
    .ListItemProduct {
      border: $border;
      border-radius: $border-radius;

      .InfoArea {
        border-top: 0!important;

        a {
          display: inline-block !important;
        }
      }
    }
  }

  .SlimHotDeal {
    display: flex;
    flex-direction: column;

    .SlimHotDealTop {
      flex: 1;
    }

    .SlimHotDealFoot {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .SlimHotDealFootFloatin {
        text-align: center;

        .InfoArea {
          text-align: center !important;
        }
      }
    }
  }
}